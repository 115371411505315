import {
  Environment,
  Network,
  Observable,
  RecordSource,
  Store,
} from "relay-runtime";
import {
  logout,
  loginToken,
  setAuthCookie,
} from "containers/Authenticated/porcelain";
import { createClient } from "graphql-ws";

const checkAuthenticatedAndQueryErrors = (_operation) => (response) => {
  const errorText = JSON.stringify(response.errors) || "";
  if (errorText.indexOf("Unauthorized") !== -1) {
    logout();
    // Relay is not returning an error so queryRenderer isn't able to detect when
    //   to redirect to the login page, but a refresh here will
    window.location.reload();
  } else if (response.data !== null && response.errors) {
    /*
      Relay swallows query errors if they don't return null at the root, in
      order to support partial success. We are not interested in partial success,
      so we reject the response if we see errors with a non-null data value.

      See https://github.com/facebook/relay/issues/1816#issuecomment-304492071

      We've since figured out that the backend can make the query response types
      non-nullable, and this will cause the root to be null if the query returns
      null. This reject can be removed once we finish applying that to the whole
      backend.
    */
    return Promise.reject(response);
  }
  return response;
};

function renewAuthToken(response) {
  const accessToken = response.headers.get("X-TOKEN");

  if (accessToken) {
    setAuthCookie(accessToken);
  }

  return response;
}

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery(operation, variables) {
  const options = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables,
    }),
  };

  const token = loginToken();
  // Add authorization token to headers if we have one
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  return fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, options)
    .then(renewAuthToken)
    .then((response) => response.json())
    .then(checkAuthenticatedAndQueryErrors(operation));
}

const wsClient = createClient({
  url: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT,
});

const subscribe = (operation, variables) => {
  return Observable.create((sink) => {
    return wsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink,
    );
  });
};

// Create a network layer from the fetch function
const network = Network.create(fetchQuery, subscribe);

// Create an environment using this network:
const source = new RecordSource();
const store = new Store(source);
const handlerProvider = null;

export default new Environment({
  handlerProvider, // Can omit.
  network,
  store,
});
