import URLSearchParams from "url-search-params";

import { isEmpty } from "lodash";
import { logout, loginToken } from "./porcelain";
import { userSignOutAsync } from "./UserSignOutMutation";

export const auth = {
  loginToken,
  logout() {
    return userSignOutAsync().then(logout);
  },
};

export const loggedIn = () => undefined !== auth.loginToken();
export const queryAccessToken = () =>
  new URLSearchParams(window.location.search).get("pat");
export const refreshWithoutToken = (location, navigate, i18n) => {
  let locationParams;

  if (location) {
    const newQuery = new URLSearchParams(location.search);
    newQuery.delete("pat");

    locationParams = {
      pathname: location.pathname,
      search: newQuery.length ? "?" + newQuery.toString() : undefined,
      hash: location.hash,
    };
  } else {
    locationParams = {
      pathname: `/${i18n.locale}/`,
    };
  }

  navigate(locationParams, { replace: true });
};

export const postLoginRedirect = async (
  payload,
  redirect,
  navigate,
  i18n,
  location,
) => {
  const locale = isEmpty(payload.user?.locale)
    ? i18n.locale
    : payload.user.locale;
  // This is to fix the redirect below. Without the console log
  // for some reason the location.pathname is /dashboard
  // regardless of the actual path
  console.log(location.pathname); // DO NOT REMOVE WITHOUT TESTING--see above
  if (redirect) {
    navigate(redirect);
  } else {
    if (payload.user.role === "ADVISOR" || payload.user.role === "SALES") {
      if (location.pathname.match(/partner|zendesk/)) {
        window.location.reload();
      } else {
        navigate(`/${locale}/partner`);
      }
    } else if (payload.user.role === "ADMIN") {
      if (
        location.pathname.match(/admin/) ||
        location.pathname.match(/partner\//) ||
        location.pathname.match(/call_center\//)
      ) {
        window.location.reload();
      } else {
        navigate(`/${locale}/admin/advisors`);
      }
    } else if (payload.user.role === "SUPERVISOR") {
      if (
        location.pathname.match(/supervisor/) ||
        location.pathname.match(/partner\//)
      ) {
        window.location.reload();
      } else {
        navigate(`/${locale}/supervisor/experts`);
      }
    } else if (payload.user.role === "DEACTIVATED_ADVISOR") {
      navigate(`/${locale}/partner/billing`);
    } else if (window.location.pathname.includes("password")) {
      navigate(`/${locale}/dashboard`);
    } else {
      window.location.reload();
    }
  }
};

export { login, setResetToken, getResetToken } from "./porcelain";
