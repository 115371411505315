import * as Sentry from "@sentry/browser";
import cookie from "cookie";
import { jwtDecode } from "jwt-decode";

import Metrics, { refreshCurrentFeatureToggles } from "helpers/metrics";
// Key in local storage under which to store auth access token
export const authKey = "access_token";
export const resetTokenKey = "reset_token";

const domain = process.env.REACT_APP_COOKIE_DOMAIN
  ? () => process.env.REACT_APP_COOKIE_DOMAIN
  : () => {
      const domainParts = window.location.hostname.split(".");

      if (domainParts.length === 1) {
        return domainParts.join(".");
      }

      return domainParts.slice(-2).join(".");
    };

const oldDomain = process.env.REACT_APP_OLD_COOKIE_DOMAIN;
const secure =
  process.env.REACT_APP_DEPLOY_ENV === "development" ? false : true;

// We need to get rid of cookies that are causing refresh loops
const removeOldCookie = () => {
  if (oldDomain) {
    const maxAge = 0;
    const expires = new Date();
    const path = "/";

    window.document.cookie = cookie.serialize(authKey, "", {
      maxAge,
      expires,
      path,
      oldDomain,
      secure: secure,
    });
    window.document.cookie = cookie.serialize(resetTokenKey, "", {
      maxAge,
      expires,
      path,
      oldDomain,
      secure: secure,
    });
  }
};

export const setAuthCookie = async (newToken) => {
  const path = `/`;
  const tokenContent = jwtDecode(newToken);
  const expires = new Date();
  expires.setTime(tokenContent.exp * 1000);

  window.document.cookie = cookie.serialize(authKey, newToken, {
    path,
    domain: domain(),
    expires,
    secure: secure,
  });
};

export const login = async ({ accessToken, user }) => {
  if (accessToken) {
    removeOldCookie();
    setAuthCookie(accessToken);
    await refreshCurrentFeatureToggles();
    if (user) {
      return new Promise((resolve, _reject) => {
        const { uuid, referredBy, role, householdId } = user;
        Sentry.getIsolationScope().setUser(user);

        Metrics.identify(uuid, { referredBy, role, householdId }, resolve);
        setTimeout(resolve, 300);
      });
    }
  }
};

export const logout = () => {
  const maxAge = 0;
  const expires = new Date();
  const path = "/";

  removeOldCookie();
  window.document.cookie = cookie.serialize(authKey, "", {
    maxAge,
    expires,
    path,
    domain: domain(),
    secure: secure,
  });
  window.document.cookie = cookie.serialize(resetTokenKey, "", {
    maxAge,
    expires,
    path,
    domain: domain(),
    secure: secure,
  });

  Sentry.getIsolationScope().setUser({});
  return refreshCurrentFeatureToggles();
};

export const loginToken = () => {
  const cookies = cookie.parse(window.document.cookie);
  return cookies[authKey];
};

export const setResetToken = (resetToken) =>
  (window.document.cookie = cookie.serialize("reset_token", resetToken, {
    path: "/",
    secure: secure,
    domain: domain(),
  }));

export const getResetToken = () => cookie.parse(window.document.cookie);
